import { useEffect, useState } from "react";
import InEvidenzaCarouselTotem from "./InEvidenzaCarouselTotem";
import axios from "axios";
import { Link } from "react-router-dom";

const InEvidenzaTotem = () => {
  const [data, setData] = useState(null);
  const currentLanguage = localStorage.getItem("i18nextLng")?.toLowerCase();

  useEffect(() => {
    axios
      .get(
        `https://backend.biassono.bbsitalia.com/${currentLanguage}/api/homepage/top-evidenza`
      )
      .then((response) => setData(response.data))
      .catch((error) => console.log(error));
  }, []);

  console.log("DATA", data?.rows?.[0]);

  return (
    <div className={"h-[65vh] flex flex-col align-center"}>
      <div className="flex justify-center items-center z-100 w-[100%]">
        <p className="text-biassonoAzure text-4xl font-bold text-center p-3">
          Notizie in evidenza:{" "}
        </p>
        <div></div>
      </div>
      <div className="flex justify-center items-center w-[100%] h-[400px] margin-auto mb-20">
        <Link
          to={
            data?.rows?.[0]?.type === "news"
              ? `/news/${data?.rows?.[0]?.id}`
              : `/eventi-e-rassegne/${data?.rows?.[0]?.id}`
          }
          className={"w-[100vh] flex align-center justify-center mt-12"}
        >
          <img
            src={`https://backend.biassono.bbsitalia.com${data?.rows?.[0]?.immagine_anteprima}`}
            className={"w-[90%] h-full lg:h-[28rem] object-cover"}
          />
        </Link>
      </div>
      <InEvidenzaCarouselTotem />
      <div className="flex justify-center items-center z-100">
        <p className="text-biassonoAzure text-2xl font-bold border-2  p-3 border-solid rounded-full shadow-home">
          Contattaci: 111 222 3344
        </p>
      </div>
    </div>
  );
};

export default InEvidenzaTotem;
